import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="mx-4">
      <div className="container-1">
          <div className="my-12">
                <h1>お問合せいただき有難うございます</h1>
                <p>後ほどご連絡させていただきますので少々お待ちください</p>        
          </div>
      </div>
    </section>
  </Layout>
);
